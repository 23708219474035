import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import Iskur from "../images/denizli-iplik-firmalar.jpg"

const Urunler = () => (
  <Layout>
    <SEO title="Ürünler" />

    <PageHeader title="Ürünler" />

    <div className="h-100 py-4">
      <div className="main pb-2 w-full text-gray-700">
        <article className="mb-5 container mx-auto">
          <div className="w-full px-10 text-lg">
            <p className="pt-10 pb-5">
              MRC Tekstil olarak Denizli’de İplik Tüccarı olarak yolculuğumuz
              kuruluş tarihimiz olan 1842 yılında başlamaktadır. Denizli tekstil
              sektörüne yerli ve yabancı firmalardan iplik temin ederek
              hizmetimize devam etmekteyiz.
            </p>
            <p className="pb-5">
              2011′den bugüne İSKUR (Maraş ) firmasının ürettiği ipliklerin
              bayiliğini yapmaktayız. Aradığınız kaliteyi, İSKUR’un ürettiği
              aşağıdaki iplik çeşitlerimizde bulacağınıza eminiz.
            </p>
          </div>

          <div className="w-full px-10 flex justify-center">
            <img src={Iskur} alt="denizli iplik firmaları" />
          </div>

          <div className="w-full p-10 prose prose-md md:prose-lg">
            <ul>
              <li>Kalın numara denim ipliği</li>
              <li>Core-yarn</li>
              <li>Şantuk</li>
              <li>Ne 20/1-40/1 aralığında karde iplik</li>
              <li>Ne 20/1-80/1 aralığında penye ve supima</li>
              <li>Viskon İplik</li>
              <li>Tencel İplik</li>
              <li>Organik İplikler</li>
            </ul>

            <p>
              <strong>Open End 100% Pamuk Dokuma İplikler</strong>
            </p>

            <ul>
              <li>6/1 – 30/1 Open End Düz İplikler</li>
              <li>6/1 – 20/1 Open End Şantuklu İplikler</li>
            </ul>

            <p>
              <strong>
                Ring Karde 100% Pamuklu, Şantuklu ve Likralı İplikler
              </strong>
            </p>
            <ul>
              <li>6/1 – 30/1 Ring Karde Pamuk Düz İplik</li>
              <li>6/1 – 30/1 Ring Karde Santuklu Pamuk İplik</li>
              <li>10/1 – 30/1 Ring Karde Likralı Pamuk İplik</li>
            </ul>

            <p>
              <strong>Ring Penye 100% Pamuk Dokuma ve Likralı İplikler</strong>
            </p>
            <ul>
              <li>14/1 – 60/1 Penye Pamuk Düz İplik</li>
              <li>16/1 – 40/1 Ring Penye Likralı Pamuk İplik</li>
            </ul>
            <p>
              <strong>
                Ayrıca RING tesisimizde üretimde olan iplik çeşitleri
              </strong>
            </p>
            <ul>
              <li>Better Cotton İplik Çeşitleri</li>
              <li>100% Tencell İplik</li>
              <li>Tencell/Pamuk Karışımlı İplik</li>
              <li>100% Viskon İplik</li>
              <li>Viskon/Pamuk Karışımlı İplik</li>
              <li>100% Bambo İplik</li>
              <li>Bambo/Pamuk Karışımlı İplik</li>
              <li>Polyester/Viskon Karışımlı İplikler Ekru, Gri ve Renkli</li>
              <li>100% Pima İplik</li>
              <li>Organik İplik</li>
              <li>100% Pamuk Melanj Gri ve Renkli İplik</li>
              <li>100% Viskon Melanj Gri ve Renkli İplik</li>
              <li>Pamuk/Polyester Karışımlı İplikler Ekru, Gri ve Renkli</li>
              <li>100% Pamuk Compact İplikler Ne 30/1, 40/1, 50/1, 60/1</li>
            </ul>
          </div>

          <div className="w-1/2">
            <div className="col-span-1"></div>

            <div className="col-span-1"></div>
          </div>

          <div className="prose prose-md"></div>
        </article>
      </div>
    </div>
  </Layout>
)

export default Urunler
